interface Query {
  className: string
  properties: object
}

export interface GQLObjectBase {
  gid: ID
  id: ID
}

// @ts-expect-error
const getCache = () => window.apolloCache.data.data;
const ApolloCacheFinder = {
  getObject: (type: string, id: string | number | undefined = undefined) => {
    const cache = getCache();
    const key = (id == undefined) ? type : `${type}:${id}`;
    return cache[key];
  },
  findMany: ({ className, properties }: Query): [GQLObjectBase] => {
    const cache = getCache();
    const cacheKeys = Object.keys(cache);
    let candidates = cacheKeys.filter((key) => {
      if (!key.startsWith(className + ":")) { return false; }
      return cache[key].__typename === className;
    });

    candidates = candidates.filter(key => {
      for (const property in properties) {
        if (cache[key][property] !== properties[property]) {
          return false;
        }
      }
      return true;
    });
    return candidates.map(x => cache[x]) as [GQLObjectBase];
  },

  findOne: ({ className, properties }: Query): GQLObjectBase | null => {
    const candidates = ApolloCacheFinder.findMany({ className, properties });
    return candidates.length > 0 ? candidates[0] : null;
  }
};

export default ApolloCacheFinder;
