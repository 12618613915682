import React from "react";
import { QueryWrapper } from "./QueryWrapper";

export function gqlSubscribe(query, output);
export function gqlSubscribe(query, variables, output);

export function gqlSubscribe(query, variablesOrOutput, output = null) {
  let variables = variablesOrOutput;
  if (output === null) {
    output = variablesOrOutput;
    variables = {};
  }

  const fetchPolicy = variables.fetchPolicy || "cache-first";

  const extraVars = { fetchPolicy, nextFetchPolicy: "cache-only", context: { isSubscription: true } };

  return <QueryWrapper query={query} variables={variables} output={output} extraVars={extraVars} />;
}

export function apolloSubscription(opts) {
  opts.context = { isSubscription: true };
  return window.apolloClient.watchQuery(opts);
}
