import { useCorsisQuery } from "./useCorsisQuery";

export const useCorsisSubscription = (query, variables, extraVars: any = {}) => {
  extraVars = {
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-only",
    context: { isSubscription: true },
    ...extraVars
  };
  return useCorsisQuery(query, variables, extraVars);
};
