import { cciaQuery as query } from "gql/ccia.graphql";
import React from "react";
import { LoadingScreen } from "../../common/components";
import { useCorsisSubscription } from "../../common/hooks";
import { gqlSubscribe } from "../../common/lib";
import { GQLCcia, GQLScore, GQLTrackCcia } from "../../schemas/schema";
import ApolloCacheFinder from "../apolloCacheFinder";

interface CciaQueryWrapperProps {
  requireReady?: boolean
  reportTrackId?: string | number
  updated: boolean
  ccia: GQLCcia
  callback: (ccia: GQLCcia, updated: boolean) => JSX.Element
}

const getRequestedTrack = (ccia: GQLCcia, reportTrackId?: string | number): GQLCcia | GQLTrackCcia => {
  const { report_date, id, _ready, _updated } = ccia;
  let out = ccia;
  if (reportTrackId) {
    out = ApolloCacheFinder.getObject(
        `TrackCcia:${reportTrackId}`
    );
    // on a custom CCIA this will come embedded, rather than as a reference
    if (!out) {
      out = ccia.tracks?.find(
        (x) => x.rtid?.toString() === reportTrackId?.toString()
      ) ?? emptyCcia;
    }
  }
  return { ...out, report_date, id, _ready, _updated };
};

const CciaQueryWrapper = ({ updated, requireReady, reportTrackId, ccia, callback }: CciaQueryWrapperProps): JSX.Element => {
  const [returnCcia, _setReturnCcia] = React.useState(ccia);
  const [returnUpdated, setReturnUpdated] = React.useState(updated);
  const [ready, setReady] = React.useState(!!ccia._ready);
  const [loading, setLoading] = React.useState(!ready);

  const setReturnCcia = (ccia) => {
    _setReturnCcia(getRequestedTrack(ccia, reportTrackId));
  };

  React.useEffect(() => {
    // This allows for cached objects
    let cciaObject = ApolloCacheFinder.getObject("Ccia", ccia.id);
    if (!cciaObject) {
      cciaObject = ccia;
    }
    if (cciaObject?._ready) {
      setReturnUpdated(ccia._updated!);
      setReady(true);
      setReturnCcia(cciaObject);
      setLoading(false);
    }
  }, [ccia._updated, ccia.id, ccia._ready]);

  if (loading || (requireReady && !ready)) {
    return <LoadingScreen type={"shimmer"} />;
  }
  return callback(returnCcia, returnUpdated);
};

interface ICciaQueryOptions {
  reportTrackId?: string | number
  to_date?: string
  withCharts?: boolean
  withExplainers?: boolean
  withDimensions?: boolean
  withQuartiles?: boolean
  requireReady?: boolean
}

type ICciaQueryCallback = (ccia: GQLCcia, updated: boolean) => any;

interface CciaQueryArguments {
  reportId: ID
  to_date?: string
}

const unpackCciaQueryArguments = (
  reportId: ID,
  optionsOrCallback: ICciaQueryOptions | ICciaQueryCallback,
  callback?: ICciaQueryCallback
): {
    options: ICciaQueryOptions
    callback: (ccia: GQLCcia, updated: boolean) => any
    queryVariables: CciaQueryArguments
  } => {
  const queryVariables: CciaQueryArguments = { reportId };
  let options: ICciaQueryOptions;
  if (!callback) {
    callback = optionsOrCallback as ICciaQueryCallback;
    options = {};
  } else {
    // @ts-expect-error
    options = optionsOrCallback;
  }
  options.to_date = options.to_date ?? "publish";

  if (options.to_date != "publish") {
    queryVariables.to_date = options.to_date;
  }

  return { options, callback, queryVariables };
};

window.__cciaDedupe = {};

interface CciaExtraVars {
  level?: number
}

const cciaDedupe = (reportId: ID, options: ICciaQueryOptions = {}, queryVariables): { [key: string]: any } => {
  const extraVars: CciaExtraVars = {};
  window.__cciaDedupe[reportId] ||= new Set();
  const set = window.__cciaDedupe[reportId];
  const addOrBark = (key) => {
    if (!set.has(key) && set.size > 0) {
      alert("cciaQuery was already requested with different options for reportId: " + reportId);
    }

    if (set.has(key)) {
      // extraVars.fetchPolicy = "cache-only";
    } else {
      set.add(key);
    }
  };

  for (const variable of ["withQuartiles", "withDimensions", "withCharts", "withExplainers"]) {
    if (options[variable]) {
      extraVars[variable] = true;
    }
    extraVars.level ||= 1;
    if (options.withCharts) {
      extraVars.level = Math.max(extraVars.level, 3);
    }
    if (options.withDimensions) {
      extraVars.level = Math.max(extraVars.level, 2);
    }
  }
  addOrBark(JSON.stringify(extraVars));

  return { extraVars, queryVariables };
};

export function cciaQuery(reportId: ID, callback: ICciaQueryCallback): null;
export function cciaQuery(reportId: ID, options: ICciaQueryOptions, callback: ICciaQueryCallback): null;
export function cciaQuery(
  reportId: ID,
  optionsOrCallback: ICciaQueryOptions | ICciaQueryCallback,
  _callback?: ICciaQueryCallback
): null {
  let { options, callback, queryVariables } = unpackCciaQueryArguments(reportId, optionsOrCallback, _callback);
  let extraVars;
  ({ extraVars, queryVariables } = cciaDedupe(reportId, options, queryVariables));
  console.log("cciaQuery", queryVariables, extraVars);
  // extraVars["fetchPolicy"] = "network-only";

  return gqlSubscribe(query, { ...queryVariables, ...extraVars }, ({ ccia }) => {
    const updated = !!ccia._updated;

    return <CciaQueryWrapper reportTrackId={options.reportTrackId}
                             ccia={ccia} callback={callback} updated={updated} {...options} />;
  });
}

export class EmptyCciaObject implements GQLCcia {
  id = "0";
  gid = "0";
  _ready = false;
  tracks = [];
  score = emptyScoreObject;
}

const emptyScoreObject: GQLScore = {
  value: 0,
  gid: "0",
  color: "gray",
  label: "N/A",
  capability_score: 0
};

const emptyCcia = new Proxy(new EmptyCciaObject(), {
  get(target, prop) {
    if (target[prop] !== undefined) return target[prop];
    if (prop.toString().match(/^score|leaders|laggards$/)) {
      return emptyScoreObject;
    }

    return "";
  }
});

interface cciaOutputHash {
  ready: boolean
  updated: boolean
}

const updateCciaObject = (ccia: GQLCcia, options, setCcia, setReady, setUpdated) => {
  if (ccia._updated) {
    if (options.reportTrackId) {
      setCcia(
        ccia.tracks?.find(
          (x) => x.rtid?.toString() === options.reportTrackId?.toString()
        ) ?? emptyCcia
      );
    } else {
      setCcia(ccia);
    }
  }

  setUpdated(ccia._updated);
  setReady(ccia._ready);
};

const _useCcia = (reportId: ID, options: ICciaQueryOptions = {}): [GQLCcia, cciaOutputHash] => {
  const [ccia, setCcia] = React.useState<GQLCcia>(emptyCcia);
  const [updated, setUpdated] = React.useState(false);
  const [ready, setReady] = React.useState(false);

  let extraVars;
  let { queryVariables } = unpackCciaQueryArguments(reportId, options);
  ({ extraVars, queryVariables } = cciaDedupe(reportId, options, queryVariables));

  const { loading, error, data } = useCorsisSubscription(query, queryVariables, extraVars);

  if (error) {
    console.error(error);
  }

  React.useEffect(() => {
    const subscription = PubSub.subscribe(`update.Ccia.${reportId}`, (event, message) => {
      if (message._ready) {
        updateCciaObject(message, options, setCcia, setReady, setUpdated);
      }
    });
    return () => { PubSub.unsubscribe(subscription); };
  }, []);

  React.useEffect(() => {
    if (!loading && !error) {
      updateCciaObject(data?.ccia, options, setCcia, setReady, setUpdated);
    }
  }, [loading, error]);

  return [ccia, { ready, updated }];
};

export const useCcia = (reportId: ID, _options: ICciaQueryOptions = {}): [GQLCcia, cciaOutputHash] => {
  return _useCcia(reportId, _options);
};

export const useTrackCcia = (
  reportId: ID,
  reportTrackId: ID,
  _options: ICciaQueryOptions = {}
): [GQLTrackCcia, cciaOutputHash] => {
  return _useCcia(reportId, { ..._options, reportTrackId });
};

export const CciaQuery = ({
  reportId,
  options,
  children
}: {
  reportId: string | number
  options?: ICciaQueryOptions
  children: (ccia: GQLCcia, updated: boolean) => any
}): JSX.Element => {
  const [ccia, { updated }] = useCcia(reportId as string, options);
  return children(ccia, updated);
};
